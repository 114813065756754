<template>
    <div class="payment-confirm">
        <v-form ref="form" id="form" v-model="valid" lazy-validation>
            <layout :header="$i18n.locale == 'th' ? 'ปลอดภัยด้วย CHILLPAY' :'SECURED BY CHILLPAY'"
                @goback="$router.push('/PaymentDetail/' + $route.params.id)" :bodyStrong="false"
                :class="{'blur-page': paymentConfirm}">
                <template v-slot:content>
                    <v-col cols="12" class="pa-0" xs="12" sm="7" md="5" lg="4">
                        <v-row class="px-3 payment-confirm-body" no-gutters>
                            <v-col cols="12" class="mt-5 mb-2 text-center">
                                <div>{{$i18n.locale == 'th' ? couponById.name: couponById.name_en}}</div>
                                <div>
                                    <h1>{{formatMoney(couponById.price)}} {{$i18n.locale == 'th' ? 'บาท': 'THB'}}</h1>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-subheader class="pl-0">
                                    {{$i18n.locale == 'th' ? 'ช่องทางการชำระเงิน': 'Payment Method'}}</v-subheader>
                                <v-expansion-panels focusable mandatory>
                                    <v-expansion-panel @click="selectedPaying('creditcard')">
                                        <v-expansion-panel-header>Credit Card</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="4">
                                                    <v-img src="/Image/visa@3x.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="8">{{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}}
                                                    VISA,DEBIT,MASTER CARD</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Internet Banking</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" dense class="mt-3" justify="center"
                                                v-for="(bank,index) in netBank" :key="index+'bank'">
                                                <v-col cols="2">
                                                    <input type="radio" :id="bank.remark" v-model="typeOfPay"
                                                        :value="bank.code" />
                                                </v-col>
                                                <v-col cols="8">
                                                    <label :for="bank.remark">
                                                        <v-row align="center">
                                                            <v-col cols="3">
                                                                <v-img :src="bank.img"></v-img>
                                                            </v-col>
                                                            <v-col cols="9" class="pl-2">
                                                                {{$i18n.locale=='th'? bank.title: bank.title_en}}
                                                            </v-col>
                                                        </v-row>
                                                    </label>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel @click="selectedPaying('payplus_kbank')">
                                        <v-expansion-panel-header>Payplus KBANK</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row v-if="typeOfPay == 'payplus_kbank'" align="center" class="mt-3">
                                                <v-col cols="12" class="text-center">
                                                    <v-img src="/Image/payplus.png" contain height="50"></v-img>
                                                </v-col>
                                                <v-col cols="12" class="py-0">
                                                    <v-subheader class="text-center px-1">
                                                        {{$i18n.locale == 'th' ? 'กรุณาระบุเบอร์โทรศัพท์ที่ลงทะเบียนกับทาง KPLUS' : 'Please enter the phone number registered with KPLUS.'}}
                                                    </v-subheader>
                                                </v-col>
                                                <v-col cols="12" class="pb-0">
                                                    <v-text-field type="number" v-model="phoneNumber" rounded outlined
                                                        class="input-pay-text" color="#463729" dense height="49px"
                                                        :placeholder="$t('phoneNumber')" :rules="PhoneRules" required>
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel @click="selectedPaying('bank_qrcode')">
                                        <v-expansion-panel-header>Bank QRCODE</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="3" class="pa-5">
                                                    <v-img src="/Image/qr.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="9">{{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}} QRCODE
                                                    Banking</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel @click="selectedPaying('epayment_truemoney')">
                                        <v-expansion-panel-header>TrueMoney Wallet</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="6">
                                                    <v-img src="/Image/true.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="6">{{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}}
                                                    TrueMoney Wallet</v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel @click="selectedPaying('JFIN')">
                                        <v-expansion-panel-header>JFIN</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-row align="center" class="mt-5">
                                                <v-col cols="3">
                                                    <v-img class="jfin-img" src="/Image/jfin_1.png" alt cover></v-img>
                                                </v-col>
                                                <v-col cols="9">
                                                    <v-row wrap no-gutters>
                                                        <v-col cols="12">
                                                            {{$i18n.locale=='th' ? 'จ่ายด้วย': 'Pay with'}} JFIN
                                                        </v-col>
                                                        <v-col cols="12 mt-2" style="font-size:14px;">
                                                            {{$i18n.locale=='th' ? 'ยอดคงเหลือ': 'Balance'}}: <span
                                                                class="red--text">{{formatMoney(balance,7)}}</span> JFIN
                                                        </v-col>
                                                        <v-col cols="12" style="font-size:14px;">
                                                            {{$i18n.locale=='th' ? 'ราคา JFIN': 'JFIN Price'}}:
                                                            {{formatMoney(couponById.jfin_price,7)}} JFIN
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-col>
                </template>
            </layout>
            <v-btn class="bottom-button" block height="50px" @click="pay()">{{$i18n.locale == 'th' ? 'ชำระ': 'PAY'}}
            </v-btn>
        </v-form>
        <pin-pad v-show="paymentConfirm" :backward="true"
            :header="$i18n.locale == 'th' ? 'กรุณายืนยัน PINCODE': 'Please enter your<br>PINCODE'" :clear="clearPin"
            @pinEmit="verifyPin" @goback="paymentConfirm=false;"></pin-pad>
        <loading v-show="loadingState"></loading>
        <alert :header="$t('failed')" type="error" :title="textErr" v-show="showAlertError"
            @done="showAlertError=false;"></alert>
        <alert :header="$t('success')" type="paySuccess"
            :title="$i18n.locale == 'th' ? 'การทำรายการเรียบร้อย <br> กรุณาชำระเงินแล้วรอรับคูปอง' :'The transaction is completed. <br> Please pay and receive the coupon.'"
            v-show="showAlertSuccess" @done="showAlertSuccess = false;"></alert>
        <alert :header="$t('success')" type="paySuccess"
            :title="$i18n.locale == 'th' ? 'การทำรายการเรียบร้อย' :'The transaction is completed.'"
            v-show="showAlertSuccessJfin" @done="$router.push('/')"></alert>
    </div>
</template>

<script>
    import Alert from "../../components/alert";
    import Layout from "../../components/layout_1";
    import Pinpad from "../../components/pinPad";
    import Loading from "../../components/loading";
    import CryptoJS from "crypto-js";
    export default {
        name: "PaymentConfirm",
        components: {
            layout: Layout,
            "pin-pad": Pinpad,
            loading: Loading,
            alert: Alert,
        },
        data() {
            let requiredTxt =
                this.$i18n.locale == "th" ? "กรุณากรอกข้อมูล" : "This field is required";
            let lenTxt =
                this.$i18n.locale == "th" ? "ข้อมูลไม่ถูกต้อง" : "This field is invalid";
            return {
                checkbox: false,
                paymentConfirm: false,
                showAlertSuccess: false,
                showAlertSuccessJfin: false,
                clearPin: false,
                loadingState: false,
                textErr: "",
                showAlertError: false,
                name: "",
                number: "",
                ex_date: "",
                cvv: "",
                phoneNumber: "",
                valid: true,
                inputRules: [(v) => !!v || requiredTxt],
                PhoneRules: [
                    (v) => !!v || requiredTxt,
                    (v) => (v && v.length == 10) || lenTxt,
                ],
                NumberCardRules: [
                    (v) => !!v || requiredTxt,
                    (v) => (v && v.length == 16) || lenTxt,
                ],
                DateRules: [
                    (v) => !!v || requiredTxt,
                    (v) => (v && v.length == 4) || lenTxt,
                ],
                CvvRules: [
                    (v) => !!v || requiredTxt,
                    (v) => (v && v.length == 3) || lenTxt,
                ],
                form: null,
                netBank: [{
                        img: "/Image/bay.png",
                        title: "ธนาคารกรุงศรี",
                        title_en: "Krungsri Bank",
                        remark: "BAY",
                        code: "internetbank_bay",
                    },
                    {
                        img: "/Image/bbl.png",
                        title: "ธนาคารกรุงเทพ",
                        title_en: "Bangkok Bank",
                        remark: "BBL",
                        code: "internetbank_bbl",
                    },
                    {
                        img: "/Image/scb.png",
                        title: "ธนาคารไทยพาณิชย์",
                        title_en: "SCB",
                        remark: "SCB",
                        code: "internetbank_scb",
                    },
                    {
                        img: "/Image/ktb.png",
                        title: "ธนาคารกรุงไทย",
                        title_en: "Krungthai Bank",
                        remark: "KTB",
                        code: "internetbank_ktb",
                    },
                    {
                        img: "/Image/tbank.png",
                        title: "ธนาคารธนชาต",
                        title_en: "Thanachart Bank",
                        remark: "TBANK",
                        code: "internetbank_tbank",
                    },
                ],
                typeOfPay: "creditcard",
            };
        },
        computed: {
            balance() {
                return this.$store.getters.balance;
            },
            eKYC_phone() {
                return this.$store.getters.eKYC_phone;
            },
            couponById() {
                return this.$store.getters.couponById(this.$route.params.id);
            },
            checkSum() {
                let txt = "";
                let secretKey =
                    "wkrNlYMJI1iChewNC9JZnEs6Fyg4B8fzDe1CW1rModQwxhlzEjSLKrMIM5sshWD1sCSgKHU0huEOSRbp5txIIDuhZqIpJJuVIVCwz4YBgPfdtAmmk4rF1NJ0YV6fnksTnWhEdhNq4vT3o28uQ25Ga183mN95m7Gu9xgoq";
                var key = Object.keys(this.dataForm);
                key.forEach((element) => {
                    txt += this.dataForm[element];
                });
                txt += secretKey;
                let cryp = CryptoJS.MD5(txt);
                return cryp.toString();
            },
        },
        methods: {
            selectedPaying(type) {
                this.typeOfPay = type;
            },
            async pay() {
                let valid = this.$refs.form.validate();
                if (valid) {
                    if (localStorage.getItem("pinReq_casalapin") == "true") {
                        this.paymentConfirm = true;
                    } else {
                        try {
                            if (this.typeOfPay == "JFIN") {
                                await this.transCoin();
                            } else {
                                let paymentUrl = await this.callTopay();
                                window.location.href = paymentUrl;
                            }
                        } catch (err) {
                            console.log("out")
                            console.log("err")
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                                "checkout failed<br> Please try again."
                            );
                        }
                    }
                }
            },
            async verifyPin(val) {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params = {
                        pin: val,
                    };
                    let response = await this.$axios.post(
                        "/verify_pin",
                        params,
                        config
                    );
                    let verifyPin = response.data;
                    if (verifyPin.status == 200) {
                        this.paymentConfirm = await false;
                        this.clearPin = await !this.clearPin;
                        try {
                            if (this.typeOfPay == "JFIN") {
                                await this.transCoin();
                            } else {
                                let paymentUrl = await this.callTopay();
                                window.location.href = paymentUrl;
                            }
                        } catch (err) {
                            console.log("out & pin")
                            console.log("err")
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                                "checkout failed<br> Please try again."
                            );
                        }
                    } else if (verifyPin.status == 400) {
                        if (verifyPin.lock <= 0) {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                `รหัสผ่านไม่ถูกต้อง<br>attemped ${verifyPin.attempted}/3` :
                                `Password has invalid<br>attemped ${verifyPin.attempted}/3`
                            );
                        } else {
                            this.errAlert(
                                this.$i18n.locale == "th" ?
                                `กรุณาลองใหม่อีกครั้ง<br> ภายในอีก ${verifyPin.lock} วินาที` :
                                `Please try again<br> in ${verifyPin.lock} sec.`
                            );
                        }
                    } else {
                        this.errAlert(
                            this.$i18n.locale == "th" ?
                            "ไม่พบรหัสผ่าน <br> กรุณา OTP ใหม่อีกครั้ง" :
                            "Password notfound<br>Please OTP again"
                        );
                    }
                } catch (err) {
                    console.log(err);
                    this.errAlert(
                        this.$i18n.locale == "th" ?
                        "กรุณาเช็คการเชื่อมต่อ" :
                        "Please check connection."
                    );
                }
                this.loadingState = false;
            },
            async callTopay(val) {
                try {
                    let phone = "";
                    if (this.typeOfPay == "payplus_kbank") {
                        phone = this.phoneNumber;
                    } else {
                        phone = this.eKYC_phone;
                    }

                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }

                    let params = {
                        coupon_id: this.couponById.objectId,
                        ChannelCode: this.typeOfPay,
                        phone_no: phone,
                        LangCode: this.$i18n.locale,
                    };
                    let response = await this.$axios.post("/purchase", params, config);
                    console.log(response);
                    return response.data.data.PaymentUrl;
                } catch (err) {
                    throw err;
                }
            },
            async transCoin() {
                this.loadingState = true;
                try {
                    let token = localStorage.getItem("sessionToken_casalapin");
                    let config = {
                        headers: {
                            'X-Parse-Session-Token': token,
                            'content-type': 'application/json',
                        }
                    }
                    let params = {
                        coupon_id: this.couponById.objectId
                    }
                    let sendPreTrans = await this.$axios.post('/pre_pay_jfin', JSON
                        .stringify(
                            params), config);
                    let params2 = {
                        transaction_id: sendPreTrans.data.data.transaction_id
                    };
                    let sendTransId = await this.$axios.post('/pay_jfin', JSON
                        .stringify(
                            params2), config);
                    console.log(sendTransId.data)
                    if (sendTransId.data.success) {
                        this.showAlertSuccessJfin = true;
                    } else {
                        try {
                            this.errAlert(sendTransId.data.message);
                        } catch (err) {
                            this.errAlert(this.$i18n.locale == "th" ?
                                "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                                "checkout failed<br> Please try again.");
                        }
                    }
                } catch (err) {
                    console.log(err)
                    this.errAlert(this.$i18n.locale == "th" ?
                        "การจ่ายเงินไม่สำเร็จ<br> กรุณาลองใหม่อีกครั้ง" :
                        "checkout failed<br> Please try again.");
                }
                this.loadingState = false;
            },
            errAlert(msg) {
                this.clearPin = !this.clearPin;
                this.textErr = msg;
                this.showAlertError = true;
            },
            validate() {
                this.$refs.form.validate();
            },
            reset() {
                this.$refs.form.reset();
            },
            resetValidation() {
                this.$refs.form.resetValidation();
            },
        },
        async beforeCreate() {
            await this.$store.dispatch("getBalance");
        }
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .payment-confirm {
        height: 100%;

        .payment-confirm-body {
            font-weight: 800;
            font-size: 15px;
            padding-bottom: 60px;
        }

        .jfin-img {
            width: 90%;
            max-width: 80px;
            height: auto;
        }

        .input-pay-text {
            .v-input__slot {
                padding-right: 8px !important;
            }

            fieldset {
                border: 2px solid $accent-color;
            }

            .v-input__append-inner {
                margin-top: 4px !important;
            }

            // width: 100%;
            // border: 2px solid $accent-color;
            // padding: 10px 10px 10px 20px;
            // border-radius: 24px;
            // height: 48px;
            // color: $base-color;
            // font-size: 14px;
            // margin-top: 16px;
            // font-weight: 800;
        }

        .v-input--is-focused {
            fieldset {
                border: 2px solid $base-color;
            }
        }

        .input-pay-check {
            .v-label {
                color: $primary-color !important;
            }
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $accent-color;
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $accent-color;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $accent-color;
        }

        .input-pay-text:focus {
            outline: none;
        }

        .bottom-button {
            z-index: 1;
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: $primary-color !important;
            border-radius: 0px !important;
        }
    }
</style>